import React, { useCallback, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Container } from 'reactstrap';
import { Home } from '../page/Home';
import { Layout } from '../layout/Layout';
import { Search } from '../page/Search';
import { Report } from '../page/Report';
import Login from './Login';
import Logout from './Logout';
// import Config from "../../config";
import { Auth, Hub } from "aws-amplify";
import PropTypes from "prop-types";
import Spinner from '../assets/loading-spinner.gif';

import '../../custom.scss'
import UserContext from '../../context/User.context';

function AppRouter({ calledFromTest }) {
    const { loading, loggedIn, setUser: setUserContext, email } = useContext(UserContext);

    const setUser = useCallback((user) => {
        let cognitoGroups = user.signInUserSession.accessToken.payload["cognito:groups"] || [];
        let userId = JSON.parse(user.attributes.identities)[0].userId || null;
        let scope = null;
        let loginError;
        if (!userId && !cognitoGroups) {
            loginError = "You don't have access to this app. Contact administrator.";
        }
        setUserContext({
            loading: false,
            email: user.attributes.email,
            name: user.attributes.name,
            loggedIn: !loginError,
            accessToken: user.signInUserSession.accessToken.jwtToken,
            loginError,
            scope
        });
    });

    useEffect(() => {
        const handleAuth = ({ payload }) => {
            switch (payload.event) {
                case "signIn":
                    return setUser(payload.data);
                case "signOut":
                    return setUserContext({ email: null, loggedIn: false, loginError: null });
                default:
                    return console.log(payload);
            }
        };

        if (!calledFromTest) {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    if (user.attributes.email !== email) {
                        // I am adding this so that it only sets the user
                        setUser(user);
                    }
                })
                .catch((error) => {
                    console.error("Auth error:", error);
                    if (loading) {
                        setUserContext({ loading: false });
                    }
                });

            Hub.listen("auth", handleAuth);

            return () => Hub.remove("auth", handleAuth);
        }
    }, [calledFromTest, email, loading, setUser, setUserContext]);

    return (
        <Router>
            {loggedIn ? <Layout /> : null}
            {!loading ? (
                <Container as="div">
                    <Switch>
                        <Route exact path='/logout' render={() => <Logout />} />
                        <Route exact path="/login">
                            {!loggedIn ? <Login /> : <Redirect to="/" />}
                        </Route>
                        {!loggedIn && <Redirect to={{ pathname: "/login" }} />}
                        <Route exact path='/' render={() => <Home />} />
                        <Route exact path='/search' render={() => <Search />} />
                        <Route path="/Report/:partyId" render={(props) => <Report {...props} />} />
                        <Redirect to="/" />
                    </Switch>
                </Container>
            ) : (
                <div className="custom-pgi-loading-spinner">
                    <img className="center" src={Spinner} alt="Loading..."></img>
                </div>
            )}
        </Router>
    );
}

AppRouter.propTypes = {
    calledFromTest: PropTypes.bool,
};

export default AppRouter;
