import React, { Component } from 'react';
import {
  Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './NavMenu.css';


export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.togglePlatform = this.togglePlatform.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.state = {
      userDropDown: false,
      platformDropdownOpen: false,
      collapsed: true,
      userDisplayName: null,
      userEmailId: null
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  toggleUserMenu() {
    this.setState({
      userDropDown: !this.state.userDropDown
    });
  }

  togglePlatform() {
    this.setState({
      platformDropdownOpen: !this.state.platformDropdownOpen
    });
  }

  async componentDidMount() {
    const { attributes: { name, email } } = await Auth.currentAuthenticatedUser();
    let displayName;
    if (name) {
      let nameArray = name.split(",");
      displayName = `${nameArray[1]} ${nameArray[0]}`;
    }
    this.setState({ userDisplayName: displayName, userEmailId: email });
  }

  render() {
    return (
      <header>
        <Navbar className="custom-pgi-navbar navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 pds-color-bg-primary-blue-1 pds-color-font-white" light>
          <Container>
            <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <Dropdown isOpen={this.state.platformDropdownOpen} toggle={this.togglePlatform}>
                    <DropdownToggle className="pds-color-bg-white pds-color-font-primary-blue-2" caret>
                      Platform
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>Select Platform:
                        <NavLink tag={Link} to="/search">All</NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
              </ul>
            </Collapse>
            <NavbarBrand tag={Link} to="/">Client Reporting</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <Dropdown isOpen={this.state.userDropDown} toggle={this.toggleUserMenu}>
                    <DropdownToggle className="btn btn-outline-light" caret nav>
                      Hi! {this.state.userDisplayName}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        {this.state.userEmailId}
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <a href="/logout" onClick={() => {
                          Auth.signOut();
                        }}>Logout</a>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
        <div className="header-margin-bottom"></div>
      </header>
    );
  }
}
