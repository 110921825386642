import React, { Component } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { Link } from "react-router-dom";
import "../sass/_common.scss";
import "../sass/_client.scss";
import "../sass/_report.scss";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <br />
        <h2 className="text-center">Client Reporting</h2>
        <br />
        <div className="container">
          <div className="custom-pgi-home-cards">
            <Card className="custom-pgi-card">
              <CardBody>
                <CardTitle tag="h5">IAA (Platforms)</CardTitle>
                <Button className="btn-primary" tag={Link} to="/search">
                  View All Platforms
                </Button>
              </CardBody>
            </Card>
            <Card className="custom-pgi-card">
              <CardBody>
                <CardTitle tag="h5">TOOLS</CardTitle>
                <Button href="#">Coming Soon</Button>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
