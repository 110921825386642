import React, { Component } from "react";
import Spinner from "../assets/loading-spinner.gif";
import axios from "axios";
import "../sass/_common.scss";
import {
  getReportById,
  getEntityById,
  filterPortfolios,
  deleteReportFromRecord,
  deleteEntityFromReport,
  getNewReportId,
  getPortfolioById,
  getNextPortfolioSequence,
  createSchedule,
  getReports,
  getSchedule,
} from "../../utilities/reportHelper";
import { SCHEDULE } from "../global/schedule";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardSubtitle,
  CardBody,
  CardTitle,
  Table,
  Input,
} from "reactstrap";
import { Auth } from "aws-amplify";
import Config from "../../config";
import { Link } from "react-router-dom";

export class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingData: false,
      loadAddNewReport: false,
      domainData: null,
      reportData: null,
      model: false,
      reportId: 0,
      entityId: 0,
      entity: null,
      partyId: this.props.match.params.partyId,
      isScheduledMonthly: false,
      isScheduledQuarterly: false,
      isScheduledYearly: false,
      isConsolidated: false,
      hideEntities: false,
      workflowApproved: false,
      reportModal: false,
      reportDeleteModal: false,
      entityDeleteModal: false,
      entitiesAddModal: false,
      partyModal: false,
      filteredPortfolios: null,
      userEmail: null,
      entityStatusModal: false,
      entityCurrentTerminatedStatus: false,
      toggleEntityRefId: null,
    };

    this.togglePartyModal = this.togglePartyModal.bind(this);
    this.toggleReportModal = this.toggleReportModal.bind(this);
    this.toggleEntityReportingStatus =
      this.toggleEntityReportingStatus.bind(this);
    this.toggleEntityStatusModal = this.toggleEntityStatusModal.bind(this);
    this.toggleEntityStatus = this.toggleEntityStatus.bind(this);
    this.toggleReportDeleteModal = this.toggleReportDeleteModal.bind(this);
    this.toggleEntityDeleteModal = this.toggleEntityDeleteModal.bind(this);
    this.deleteReport = this.deleteReport.bind(this);
    this.deleteEntity = this.deleteEntity.bind(this);
    this.submitReportRecord = this.submitReportRecord.bind(this);
    this.submitParty = this.submitParty.bind(this);
    this.toggleEntitiesAddModal = this.toggleEntitiesAddModal.bind(this);
    this.submitAddEntities = this.submitAddEntities.bind(this);
  }

  togglePartyModal() {
    var record = this.state.reportData;
    var isScheduledMonthly = false;
    var isScheduledQuarterly = false;
    var isScheduledYearly = false;

    if (record !== null && record.schedule !== null) {
      if (record.schedule.includes(SCHEDULE.MONTHLY)) {
        isScheduledMonthly = true;
      }

      if (record.schedule.includes(SCHEDULE.QUARTERLY)) {
        isScheduledQuarterly = true;
      }

      if (record.schedule.includes(SCHEDULE.YEARLY)) {
        isScheduledYearly = true;
      }
    }

    this.setState({
      partyModal: !this.state.partyModal,
      isScheduledMonthly: isScheduledMonthly,
      isScheduledQuarterly: isScheduledQuarterly,
      isScheduledYearly: isScheduledYearly,
    });
  }

  toggleReportModal(e) {
    var record = this.state.reportData;
    var reportId = e.currentTarget.hasAttribute("data-report-id")
      ? parseInt(e.currentTarget.getAttribute("data-report-id"))
      : 0;
    var isConsolidated = false;
    var hideEntities = true;
    var workflowApproved = false;

    if (reportId > 0) {
      var report = getReportById(record, reportId);
      isConsolidated = report.isConsolidated;
      hideEntities = report.hideEntities;
      workflowApproved = report.workflowApproved;
    }

    this.setState({
      reportModal: !this.state.reportModal,
      reportId: reportId,
      isConsolidated: isConsolidated,
      hideEntities: hideEntities,
      workflowApproved: workflowApproved,
    });
  }

  toggleReportDeleteModal(e) {
    var reportId = e.currentTarget.hasAttribute("data-report-id")
      ? parseInt(e.currentTarget.getAttribute("data-report-id"))
      : 0;

    this.setState({
      reportDeleteModal: !this.state.reportDeleteModal,
      reportId: reportId,
    });
  }

  toggleEntityDeleteModal(e) {
    var record = this.state.reportData;
    var reportId = e.currentTarget.hasAttribute("data-report-id")
      ? e.currentTarget.getAttribute("data-report-id")
      : null;
    var entityId = e.currentTarget.hasAttribute("data-entity-id")
      ? e.currentTarget.getAttribute("data-entity-id")
      : null;

    var report = getReportById(record, reportId);
    var entity = getEntityById(report, entityId);

    this.setState({
      entityDeleteModal: !this.state.entityDeleteModal,
      reportId: reportId,
      entityId: entityId,
      entity: entity,
    });
  }

  toggleEntityReportingStatus(e, entity) {
    var reportId = e.currentTarget.hasAttribute("data-report-id")
      ? e.currentTarget.getAttribute("data-report-id")
      : null;
    var entityId = e.currentTarget.hasAttribute("data-entity-id")
      ? e.currentTarget.getAttribute("data-entity-id")
      : null;

    this.setState({
      entityStatusModal: !this.state.entityStatusModal,
      entityCurrentTerminatedStatus: entity.isTerminated,
      toggleEntityRefId: entity.entityRefId,
      reportId: reportId,
      entityId: entityId,
      entity: entity,
    });
  }

  toggleEntityStatusModal(e) {
    var record = this.state.reportData;
    var reportId = e.currentTarget.hasAttribute("data-report-id")
      ? e.currentTarget.getAttribute("data-report-id")
      : null;
    var entityId = e.currentTarget.hasAttribute("data-entity-id")
      ? e.currentTarget.getAttribute("data-entity-id")
      : null;

    var report = getReportById(record, reportId);
    var entity = getEntityById(report, entityId);

    this.setState({
      entityStatusModal: !this.state.entityStatusModal,
      reportId: reportId,
      entityId: entityId,
      entity: entity,
    });
  }

  toggleEntitiesAddModal(e) {
    var record = this.state.reportData;
    var reportId = e.currentTarget.hasAttribute("data-report-id")
      ? e.currentTarget.getAttribute("data-report-id")
      : null;
    // Copy the domainData (because we don't want a reference that changes it).
    var filteredPortfolios = this.state.domainData.portfolios.map((x) => x);

    if (reportId != null) {
      filteredPortfolios = filterPortfolios(record.reports, filteredPortfolios);
      this.setState({
        entitiesAddModal: !this.state.entitiesAddModal,
        filteredPortfolios: filteredPortfolios,
        reportId: reportId,
      });
    } else {
      this.setState({
        entitiesAddModal: !this.state.entitiesAddModal,
      });
    }
  }

  deleteReport(e) {
    e.preventDefault();
    var record = this.state.reportData;
    var reportId = this.state.reportId;

    deleteReportFromRecord(record, reportId);

    var data = record;
    this.postData("DELETE REPORT", data);

    this.toggleReportDeleteModal(e);
  }

  toggleEntityStatus(e) {
    e.preventDefault();

    var partyId = this.state.reportData.partyId;
    var partyName = this.state.reportData.partyName;
    var schedule = this.state.reportData.schedule;
    var reports = this.state.reportData.reports;

    var reportId = this.state.reportId;

    if (reportId != null) {
      reports.forEach((report) => {
        if (report.reportId === parseInt(reportId)) {
          report.entities.forEach((entity) => {
            if (entity.entityId === this.state.entityId) {
              entity.isTerminated = !this.state.entityCurrentTerminatedStatus;
            }
          });
        }
      });

      var data = {
        partyId: partyId,
        partyName: partyName,
        schedule: schedule,
        reports: reports,
      };

      this.postData("UPDATE PORTFOLIO STATUS", data);
      this.toggleEntityStatusModal(e);
    }
  }

  deleteEntity(e) {
    e.preventDefault();
    var record = this.state.reportData;
    var reportId = this.state.reportId;
    var entityId = this.state.entityId;
    var report = getReportById(record, reportId);

    deleteEntityFromReport(report, entityId);

    var data = record;
    this.postData("DELETE PORTFOLIO", data);

    this.toggleEntityDeleteModal(e);
  }

  async componentDidMount() {
    var self = this;
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    axios
      .request({
        url: "/portfolios/" + self.state.partyId,
        method: "get",
        baseURL: Config.api.domainBaseAPI,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then(function (result) {
        if (result.data.Item && result.data.Item !== null) {
          self.setState({ domainData: result.data.Item, loading: false });
          if (self.state.domainData !== null) {
            self.loadReportData();
          }
        } else {
          self.setState({ domainData: null, loading: false });
        }
      })
      .catch(function (err) {
        console.log(err);
      });

    Auth.currentUserInfo()
      .then(function (result) {
        if (result.attributes.email) {
          self.setState({ userEmail: result.attributes.email });
        }
      })
      .catch(function (reason) {
        Auth.signOut();
      });
  }

  async loadReportData() {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    this.setState({ loadingData: true });
    var self = this;
    axios
      .request({
        url: "/party/" + self.state.partyId,
        method: "get",
        baseURL: Config.api.partyBaseAPI,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then(function (result) {
        if (result.data.Item && result.data.Item !== null) {
          self.setState({ reportData: result.data.Item, loadingData: false });
        } else {
          self.setState({ reportData: null, loadingData: false });
        }
      });
  }

  submitParty(e) {
    e.preventDefault();
    var record = this.state.reportData;
    var partyId = this.state.partyId;
    var partyName = this.state.reportData.partyName;
    var isScheduledMonthly = e.target.scheduleMonthly.checked;
    var isScheduledQuarterly = e.target.scheduleQuarterly.checked;
    var isScheduledYearly = e.target.scheduleYearly.checked;

    var reports = getReports(record);
    var schedule = createSchedule(
      isScheduledMonthly,
      isScheduledQuarterly,
      isScheduledYearly
    );

    var data = {
      partyId: partyId,
      partyName: partyName,
      schedule: schedule,
      reports: reports,
    };

    this.postData("ADD OR UPDATE CLIENT", data);
  }

  submitReportRecord(e) {
    e.preventDefault();
    var record = this.state.reportData;
    var partyId = e.target.partyId.value;
    var partyName = e.target.partyName.value;
    var reportId = parseInt(e.target.reportId.value);
    var isConsolidated = e.target.isConsolidated.checked;
    var hideEntities = !e.target.hideEntities.checked;
    var workflowApproved = e.target.workflowApproved.checked;
    var report = null;

    var reports = getReports(record);
    var schedule = getSchedule(record);

    if (reportId === 0) {
      reportId = getNewReportId(partyId);

      report = {
        reportId: reportId,
        isConsolidated: isConsolidated,
        hideEntities: hideEntities,
        workflowApproved: workflowApproved,
        entities: [],
      };

      reports.push(report);
    } else {
      var reportIdx = reports.findIndex((x) => x.reportId === reportId);
      report = getReportById(record, reportId);
      report.isConsolidated = isConsolidated;
      report.hideEntities = hideEntities;
      report.workflowApproved = workflowApproved;
      reports[reportIdx] = report;
    }

    var data = {
      partyId: partyId,
      partyName: partyName,
      schedule: schedule,
      reports: reports,
    };

    this.postData("UPDATE OR CREATE REPORT", data);
  }

  submitAddEntities(e) {
    e.preventDefault();
    var portfolioId = e.target.portfolioSelector.value;

    if (portfolioId != null) {
      var record = this.state.reportData;
      var schedule = record.schedule;
      var partyId = e.target.partyId.value;
      var partyName = e.target.partyName.value;
      var reportId = e.target.reportId.value;
      var report = getReportById(record, reportId);
      var sequence = getNextPortfolioSequence(report);
      var portfolio = getPortfolioById(
        this.state.domainData.portfolios,
        portfolioId
      );

      var entity = {
        entityTypeCode: "PORT",
        entityName: portfolio.portfolioName,
        entityRefId: portfolio.portfolioBUCode,
        entityType: "Portfolio",
        entityId: portfolioId,
        sequence: sequence,
        isTerminated: false,
      };

      report.entities.push(entity);

      var data = {
        partyId: partyId,
        partyName: partyName,
        schedule: schedule,
        reports: record.reports,
      };

      this.postData("ADD PORTFOLIO", data);
    }
  }

  async postData(action, data) {
    var self = this;
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    let payload = JSON.stringify({
      user: self.state.userEmail,
      action: action,
      data: data,
    });

    axios
      .request({
        url: "/party",
        method: "post",
        baseURL: Config.api.partyBaseAPI,
        data: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then(function (result) {
        self.loadReportData();
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  render() {
    let headingDisplayOutput;
    let reportDisplayOutput;
    let newReportForm;
    let entitiesAddForm;
    let editPartyForm;

    if (this.state.loading) {
      headingDisplayOutput = (
        <div className="custom-pgi-loading-spinner">
          <img src={Spinner} alt="Loading..."></img>
        </div>
      );
    } else {
      if (this.state.domainData !== null) {
        headingDisplayOutput = (
          <Card className="custom-pgi-large-card custom-pgi-report-listing-info">
            <CardBody>
              <CardTitle tag="h5">
                {this.state.domainData?.partyName || "No Domain Data Present"}
              </CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">
                Report records for{" "}
                {this.state.domainData?.partyName || "No Domain Data Present"}{" "}
                are listed below. You can add new reports or delete/edit
                existing reports by clicking the appropriate button next to each
                report.
                <br />
                <br />
                <Button className="btn btn-info" tag={Link} to="/search">
                  Back To Search
                </Button>
                <button
                  type="button"
                  onClick={this.toggleReportModal}
                  className="btn btn-info"
                >
                  Add Report
                </button>
                <button
                  type="button"
                  onClick={this.togglePartyModal}
                  className="btn btn-info"
                >
                  Edit Client Details
                </button>
              </CardSubtitle>
              <span>
                <strong>Schedule: </strong>
                {this.state.reportData?.schedule?.map((schedule, idx, arr) => {
                  var friendlyName = "";
                  switch (schedule) {
                    case SCHEDULE.MONTHLY:
                      friendlyName = "Monthly";
                      break;
                    case SCHEDULE.QUARTERLY:
                      friendlyName = "Quarterly";
                      break;
                    case SCHEDULE.YEARLY:
                      friendlyName = "Fiscal Year";
                      break;
                    default:
                      friendlyName = null;
                      break;
                  }
                  return (
                    <span key={schedule}>
                      {friendlyName}
                      {idx === arr.length - 1 ? "" : ", "}
                    </span>
                  );
                })}
              </span>
            </CardBody>
          </Card>
        );
      } else {
        headingDisplayOutput = (
          <Card className="custom-pgi-large-card">
            <CardBody>
              <CardTitle tag="h5">No Party Data</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">
                We could not find any party data or report data for party ID:{" "}
                {this.state.partyId}
              </CardSubtitle>
            </CardBody>
          </Card>
        );
      }
    }

    if (this.state.loadingData) {
      reportDisplayOutput = (
        <div className="custom-pgi-loading-spinner">
          <img src={Spinner} alt="Loading..."></img>
        </div>
      );
    } else {
      if (this.state.reportData !== null) {
        reportDisplayOutput = (
          <div className="container custom-pgi-reports-listing">
            {this.state.reportData.reports?.map((report) => (
              <Card
                className="custom-pgi-report-record-card"
                key={report.reportId}
              >
                <CardBody>
                  <CardTitle>
                    <b>ID:</b> {report?.reportId || "N/A"}
                  </CardTitle>
                  <div>
                    <b>Consolidated Report:</b>{" "}
                    {report.isConsolidated ? "Yes" : "No"} <br />
                    <b>Display Portfolio:</b>{" "}
                    {!report.hideEntities ? "Yes" : "No"} <br />
                    <b>Workflow Approved Report:</b>{" "}
                    {report.workflowApproved ? "Yes" : "No"} <br />
                    {report.entities != null && report.entities.length > 0 ? (
                      <Table className="custom-pgi-entity-table" striped>
                        <thead>
                          <tr>
                            <th>Portfolio Active Status</th>
                            <th>Business Code</th>
                            <th>Name</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {report.entities.map((entity) => (
                            <tr key={entity.entityId}>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    data-report-id={report.reportId}
                                    data-entity-id={entity.entityId}
                                    type="checkbox"
                                    id="reportingStatus"
                                    name="reportingStatus"
                                    checked={!entity.isTerminated}
                                    onChange={(e) =>
                                      this.toggleEntityReportingStatus(
                                        e,
                                        entity
                                      )
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                {entity.entityRefId != null
                                  ? entity.entityRefId
                                  : "N/A"}
                              </td>
                              <td>
                                {entity.entityName != null
                                  ? entity.entityName
                                  : "N/A"}
                              </td>
                              <td>
                                <Button
                                  className="btn-sm btn-delete"
                                  color="danger"
                                  data-report-id={report.reportId}
                                  data-entity-id={entity.entityId}
                                  onClick={this.toggleEntityDeleteModal}
                                >
                                  x
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <h6>No Portfolios Found</h6>
                    )}
                    <Button
                      color="primary"
                      data-report-id={report.reportId}
                      onClick={this.toggleReportModal}
                    >
                      Edit Report Record
                    </Button>
                    <Button
                      color="primary"
                      data-report-id={report.reportId}
                      onClick={this.toggleEntitiesAddModal}
                    >
                      Add Portfolio
                    </Button>
                    <Button
                      color="danger"
                      data-report-id={report.reportId}
                      onClick={this.toggleReportDeleteModal}
                    >
                      Delete Report
                    </Button>
                  </div>
                </CardBody>
              </Card>
            ))}
            <Modal
              isOpen={this.state.reportDeleteModal}
              toggle={this.toggleReportDeleteModal}
            >
              <form
                id="confirm-delete-report-form"
                onSubmit={this.deleteReport}
              >
                <ModalHeader toggle={this.toggleReportDeleteModal}>
                  Are you sure you want to delete Report {this.state.reportId}?
                </ModalHeader>
                <ModalFooter>
                  <Button color="danger" type="submit">
                    Delete
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.toggleReportDeleteModal}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
            <Modal
              isOpen={this.state.entityStatusModal}
              toggle={this.toggleEntityStatusModal}
            >
              <form
                id="confirm-terminate-entity-form"
                onSubmit={this.toggleEntityStatus}
              >
                <ModalHeader toggle={this.toggleEntityStatusModal}>
                  Are you sure you want to{" "}
                  {this.state.entityCurrentTerminatedStatus
                    ? "activate"
                    : "terminate"}{" "}
                  portfolio {this.state.toggleEntityRefId}?
                </ModalHeader>
                <ModalFooter>
                  <Button
                    color={
                      this.state.entityCurrentTerminatedStatus
                        ? "success"
                        : "danger"
                    }
                    type="submit"
                  >
                    {this.state.entityCurrentTerminatedStatus
                      ? "Activate"
                      : "Terminate"}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.toggleEntityStatusModal}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
            <Modal
              isOpen={this.state.entityDeleteModal}
              toggle={this.toggleEntityDeleteModal}
            >
              <form
                id="confirm-delete-report-form"
                onSubmit={this.deleteEntity}
              >
                <ModalHeader toggle={this.toggleEntityDeleteModal}>
                  Are you sure you want to delete the portfolio "
                  {this.state.entity?.entityName}" with business code{" "}
                  {this.state.entity?.entityRefId} from Report{" "}
                  {this.state.reportId}?
                </ModalHeader>
                <ModalFooter>
                  <Button color="danger" type="submit">
                    Delete
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.toggleEntityDeleteModal}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
          </div>
        );
      } else {
        reportDisplayOutput = null;
      }
    }

    newReportForm = (
      <div>
        <Modal isOpen={this.state.reportModal} toggle={this.toggleReportModal}>
          <form id="report-record-form" onSubmit={this.submitReportRecord}>
            <ModalHeader toggle={this.toggleReportModal}>
              Report Record
            </ModalHeader>
            <ModalBody>
              Please fill out the fields below and save the record. This will
              add a new report for the client and allow you to add Portfolio(s)
              to this report.
              <br />
              <div className="form-text">
                <b>Report ID:</b>{" "}
                {this.state.reportId === 0 ? "TBD" : this.state.reportId}
              </div>
              <div className="form-text">
                <b>Client Name:</b> {this.state.domainData?.partyName || "N/A"}
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="isConsolidated"
                  name="isConsolidated"
                  defaultChecked={this.state.isConsolidated}
                />
                <label className="form-check-label" htmlFor="isConsolidated">
                  Is Consolidated Report?
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="hideEntities"
                  name="hideEntities"
                  defaultChecked={!this.state.hideEntities}
                />
                <label className="form-check-label" htmlFor="hideEntities">
                  Display Portfolio(s)?
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="workflowApproved"
                  name="workflowApproved"
                  defaultChecked={this.state.workflowApproved}
                />
                <label className="form-check-label" htmlFor="workflowApproved">
                  Is report part of workflow approval process?
                </label>
              </div>
              <input
                type="hidden"
                id="partyId"
                name="partyId"
                value={this.state.partyId}
              />
              <input
                type="hidden"
                id="reportId"
                name="reportId"
                value={this.state.reportId}
              />
              <input
                type="hidden"
                id="partyName"
                name="partyName"
                value={this.state.domainData?.partyName || "N/A"}
              ></input>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                data-report-id={this.state.reportId}
                data-save-action="save"
                onClick={this.toggleReportModal}
              >
                Save
              </Button>
              <Button color="secondary" onClick={this.toggleReportModal}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );

    editPartyForm = (
      <div>
        <Modal isOpen={this.state.partyModal} toggle={this.togglePartyModal}>
          <form id="report-record-form" onSubmit={this.submitParty}>
            <ModalHeader toggle={this.togglePartyModal}>
              Client Details
            </ModalHeader>
            <ModalBody>
              Please fill out the fields below and save the record. This will
              update the schedule for all reports for this client.
              <br />
              <div className="form-text">
                <b>Client Name:</b> {this.state.domainData?.partyName || "N/A"}
              </div>
              <b>Schedule(s): </b>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="scheduleMonthly"
                  name="scheduleMonthly"
                  defaultChecked={this.state.isScheduledMonthly}
                />
                <label className="form-check-label" htmlFor="scheduleMonthly">
                  Monthly
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="scheduleQuarterly"
                  name="scheduleQuarterly"
                  defaultChecked={this.state.isScheduledQuarterly}
                />
                <label className="form-check-label" htmlFor="scheduleQuarterly">
                  Quarterly
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="scheduleYearly"
                  name="scheduleYearly"
                  defaultChecked={this.state.isScheduledYearly}
                />
                <label className="form-check-label" htmlFor="scheduleYearly">
                  Fiscal Year
                </label>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                data-save-action="save"
                onClick={this.togglePartyModal}
              >
                Save
              </Button>
              <Button color="secondary" onClick={this.togglePartyModal}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );

    entitiesAddForm = (
      <div>
        <Modal
          isOpen={this.state.entitiesAddModal}
          toggle={this.toggleEntitiesAddModal}
        >
          <form id="report-record-form" onSubmit={this.submitAddEntities}>
            <ModalHeader toggle={this.toggleEntitiesAddModal}>
              Add Portfolio
            </ModalHeader>
            <ModalBody>
              Select a portfolio from the list below and add it.
              <Input
                type="select"
                name="portfolioSelector"
                id="portfolioSelector"
              >
                <option value=""></option>
                {this.state.filteredPortfolios?.map((portfolio) => (
                  <option
                    key={portfolio.portfolioId}
                    value={portfolio.portfolioId}
                  >
                    {portfolio.portfolioBUCode} - {portfolio.portfolioName}
                  </option>
                ))}
              </Input>
              <input
                type="hidden"
                id="partyId"
                name="partyId"
                value={this.state.partyId}
              />
              <input
                type="hidden"
                id="reportId"
                name="reportId"
                value={this.state.reportId}
              />
              <input
                type="hidden"
                id="partyName"
                name="partyName"
                value={this.state.domainData?.partyName || "N/A"}
              ></input>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="submit"
                data-save-action="save"
                onClick={this.toggleEntitiesAddModal}
              >
                Add
              </Button>
              <Button color="secondary" onClick={this.toggleEntitiesAddModal}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );

    return (
      <div>
        <div className="container custom-pgi-centered-container">
          {headingDisplayOutput}
        </div>
        <br />
        <div className="container custom-pgi-centered-container">
          {reportDisplayOutput}
        </div>
        <div>{newReportForm}</div>
        <div>{editPartyForm}</div>
        <div>{entitiesAddForm}</div>
      </div>
    );
  }
}

export default Report;
