import React, { Component } from 'react';
import Clients from '../shared/Clients';
import { Card, CardSubtitle, CardBody, CardTitle } from 'reactstrap';
import "../sass/_common.scss";

export class Search extends Component {

  render() {
    return (
      <div>
        <div className="container custom-pgi-centered-container">
          <Card className="custom-pgi-large-card">
            <CardBody>
              <CardTitle tag="h5">Client Search</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">
                Please select a client from the list below, then click one of the buttons below to view or edit that client.
              </CardSubtitle>
              <Clients />
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}
