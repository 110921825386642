import React, { useContext } from "react";
import Config from "../../config";
import UserContext from "../../context/User.context";

export default function Logout() {
    const { loginError } = useContext(UserContext);

    const handleLogin = () => {
        const { cognito: { tokenUrl, providerId, redirectUrlSignIn, clientId, scope } } = Config;
        const scopes = encodeURIComponent(scope);
        const loginUrl = `${tokenUrl}?identity_provider=${providerId}&redirect_uri=${redirectUrlSignIn}&response_type=TOKEN&client_id=${clientId}&scope=${scopes}`;
        window.location.assign(loginUrl);
    };

    return (
        <div className="sign-in">
            {loginError ? (
                <h1 className="sign-in-error-text">{loginError}</h1>
            ) : (
                <div>
                    <div className="alert alert-success center-align-logout-msg">
                        You have been successfully logged out.
                    </div>
                    <div className="col-6 mx-auto center-align-button">
                        <button type="button" className="btn btn-lg btn-warning" onClick={(e) => {
                            e.preventDefault();
                            handleLogin();
                        }}>
                            Log Back In
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}