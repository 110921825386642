import React, { useState } from "react";
import PropTypes from "prop-types";
import UserContext from "../../context/User.context";

const UserProvider = ({ children }) => {
  const setUser = (user) => {
    updateUserDetails((prevState) => {
      return {
        ...prevState,
        ...user,
      };
    });
  };

  const userState = {
    loading: true,
    loggedIn: false,
    loginError: null,
    email: null,
    name: null,
    accessToken: null,
    scope: null,
    setUser,
  };

  const [userDetails, updateUserDetails] = useState(userState);

  return <UserContext.Provider value={userDetails}>{children}</UserContext.Provider>;
};
UserProvider.propTypes = {
  children: PropTypes.any,
};

export default UserProvider;
