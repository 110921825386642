import { createContext } from "react";

const UserContext = createContext({
  loggedIn: true,
  loginError: null,
  email: null,
  name: null,
  accessToken: null,
  scope: null,
  setUser: () => {},
});

export default UserContext;
