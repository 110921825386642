import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from "aws-amplify";
import registerServiceWorker from './registerServiceWorker';
import Config from "./config";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: Config.region,
    userPoolId: Config.cognito.userPoolId,
    userPoolWebClientId: Config.cognito.clientId,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  },
  oauth: {
    domain: Config.cognito.domain,
    scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: Config.cognito.redirectUrlSignIn,
    redirectSignOut: Config.cognito.redirectUrlSignOut,
    responseType: "token"
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'));

registerServiceWorker();
