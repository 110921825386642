import React, { useEffect } from "react";
import UserProvider from "./components/shared/UserProvider";
import AppRouter from "./components/shared/AppRouter";
import "./custom.scss";
import Config from "./config";

function App() {
  useEffect(() => {
    document.title = Config.appName;
  })

  return (
    <div className="App">
      <UserProvider>
        <AppRouter />
      </UserProvider>
    </div>
  );
}

export default App;
