import { SCHEDULE } from '../components/global/schedule';

export function getReportById(record, reportId) {
    if (record == null || !Array.isArray(record.reports) || reportId == null) {
        return null;
    }

    var reportIdx = record.reports.findIndex(x => x.reportId.toString() === reportId.toString());
    if (reportIdx === -1) {
        return null;
    }

    return record.reports[reportIdx];
}

export function getEntityById(report, entityId) {
    if (report == null || !Array.isArray(report.entities) || entityId == null) {
        return null;
    }

    var entityIdx = report.entities.findIndex(x => x.entityId === entityId);
    if (entityIdx === -1) {
        return null;
    }

    return report.entities.slice(entityIdx, entityIdx + 1)[0];
}

export function filterPortfolios(reports, portfolios) {
    if (reports == null || !Array.isArray(reports) || !Array.isArray(portfolios)) {
        return null;
    }

    reports.forEach(report=> {
        report.entities.forEach(element => {
            var itemIdx = portfolios.findIndex(x => x.portfolioId === element.entityId);
            if (itemIdx !== -1) {
                portfolios.splice(itemIdx, 1);
            }
        })
    });

    return portfolios;
}

export function deleteReportFromRecord(record, reportId) {
    if (record == null || !Array.isArray(record.reports) || reportId == null) {
        return;
    }

    record.reports.splice(record.reports.findIndex(x => x.reportId === reportId), 1);
}

export function deleteEntityFromReport(report, entityId) {
    if (report == null || !Array.isArray(report.entities) || entityId == null) {
        return;
    }

    // get the sequence for a given entityId
    const entitySequence = report.entities.filter(x => x.entityId === entityId)[0].sequence;

    // remove the entity from the report
    report.entities.splice(report.entities.findIndex(x => x.entityId === entityId.toString()), 1);

    // decrement the sequence for all entities with a sequence greater than the deleted entity
    for (let index = 0, length = report.entities.length; index < length; index++) {
        const entity = report.entities[index];
        if (entity.sequence > entitySequence) {
            entity.sequence = entity.sequence - 1;
        }
    }
}

export function getNewReportId(partyId) {
    if (partyId == null) {
        return null;
    }

    // New reportId is current time with the first n digits replaced by the partyId where n is the length of partyId.
    var currentTimestamp = Math.floor(Date.now() / 1000).toString();
    var partyIdStr = partyId.toString();
    var partyIdLength = partyIdStr.length;
    return parseInt(partyIdStr + currentTimestamp.slice(partyIdLength));
}

export function getPortfolioById(portfolios, portfolioId) {
    if (!Array.isArray(portfolios) || portfolioId == null) {
        return null;
    }

    var portfolioIdx = portfolios.findIndex(x => x.portfolioId === portfolioId);
    if (portfolioIdx === -1) {
        return null;
    }

    return portfolios[portfolioIdx];
}

export function getNextPortfolioSequence(report) {
    if (report == null || !Array.isArray(report.entities)) {
        return null;
    }

    return report.entities.length + 1;
}

export function createSchedule(isMonthly, isQuarterly, isYearly) {
    var schedule = [];
    if (isMonthly) {
      schedule.push(SCHEDULE.MONTHLY);
    }
    if (isQuarterly) {
      schedule.push(SCHEDULE.QUARTERLY);
    }
    if (isYearly) {
      schedule.push(SCHEDULE.YEARLY);
    }

    return schedule;
}

export function getReports(record) {
    if (record == null || !Array.isArray(record.reports)) {
        return [];
    }

    return record.reports;
}

export function getSchedule(record) {
    if (record == null || !Array.isArray(record.schedule)) {
        return [];
    }

    return record.schedule;
}