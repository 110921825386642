import sbx from "./environments/sbx";
import nonprd from "./environments/nonprd";
import prd from "./environments/prd";
import dr from "./environments/dr";
import local from "./environments/local";

const awsConfig =
  process.env.REACT_APP_ENV === "prd"
    ? prd
    : process.env.REACT_APP_ENV === "dr"
      ? dr
      : process.env.REACT_APP_ENV === "nonprd"
        ? nonprd
        : process.env.REACT_APP_ENV === "sbx"
          ? sbx
          : local;

export default awsConfig;