const config = {
    appName: "CRT Business Tool - nonprd",
    region: "us-east-1",
    cognito: {
        userPoolId: "us-east-1_CEuteQ9qs",
        domain: "client-reporting-user-pool-domain-nonprd.auth.us-east-1.amazoncognito.com",
        tokenUrl: "https://client-reporting-user-pool-domain-nonprd.auth.us-east-1.amazoncognito.com/oauth2/authorize",
        providerId: "ce-crt-pool-provider",
        redirectUrlSignIn: "https://crt.assetmgmt.staging.principalam.com",
        redirectUrlSignOut: "https://crt.assetmgmt.staging.principalam.com/logout",
        clientId: "th6522tr9sgl9dhr57s0pttb1",
        scope: "email openid aws.cognito.signin.user.admin",
        readOnlyGroup: null,
        updateGroup: null
    },
    api: {
        partyBaseAPI: "https://lv3lns7qn6.execute-api.us-east-1.amazonaws.com/nonprd",
        domainBaseAPI: "https://6sd9vckrof.execute-api.us-east-1.amazonaws.com/nonprd"
    }
};

export default config;
