import React, { useContext, useEffect } from "react";
import Config from "../../config";
import UserContext from "../../context/User.context";

export default function Login() {
    const { loginError } = useContext(UserContext);

    const handleLogin = () => {
        const { cognito: { tokenUrl, providerId, redirectUrlSignIn, clientId, scope } } = Config;
        const scopes = encodeURIComponent(scope);
        const loginUrl = `${tokenUrl}?identity_provider=${providerId}&redirect_uri=${redirectUrlSignIn}&response_type=TOKEN&client_id=${clientId}&scope=${scopes}`;
        window.location.assign(loginUrl);
    };

    useEffect(() => {
        if (!loginError) {
            handleLogin();
        }
        // no-qa;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="sign-in">
            {loginError ? (
                <h1 className="sign-in-error-text">{loginError}</h1>
            ) : null}
        </div>
    );
}