import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import Spinner from '../assets/loading-spinner.gif';
import { Button, Card, CardText, CardBody, CardTitle, UncontrolledCollapse, Table } from 'reactstrap';
import { Auth } from "aws-amplify";
import Config from '../../config';
export class Clients extends Component {

  constructor(props) {
    super(props);
    this.state = { loading: true, loadingView: false, clients: [], clientReports: null, currentSelection: null };
  }

  async componentDidMount() {
    var self = this;
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    axios.request({
      url: "/portfolios",
      method: "get",
      baseURL: Config.api.domainBaseAPI,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${token}`
      }
    }).then(function (res) {
      if (res.status === 200) {
        self.setState({ clients: res.data.Items, loading: false });
      }
      else {
        self.setState({ clients: null, loading: false });
      }
    }).catch(function (err) {
      self.setState({ clients: null, loading: false });
      console.log("Something went wrong!!");
    }
    );
  }

  clientSelectChange = async (e) => {
    if (e) {
      var currentValue = e.value
      this.setState({ loadingView: true, currentSelection: currentValue })
      var self = this;
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      axios.request({
        url: "/party/" + currentValue,
        method: "get",
        baseURL: Config.api.partyBaseAPI,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}`
        }
      }).then(function (res) {
        if (res.data.Item && res.data.Item !== null) {
          self.setState({ clientReports: res.data.Item, loadingView: false })
        }
        else {
          self.setState({ clientReports: null, loadingView: false })
        }
      }).catch(function (err) {
        self.setState({ clients: null, loading: false });
        console.log("Something went wrong!!");
      }
      );
    }
    else {
      this.setState({ loadingView: false, currentSelection: null })
    }
  }

  render() {
    const reportTo = { pathname: "/Report/" + this.state.currentSelection }
    let displayOutput;
    let clientsOptions = [];
    let displayReportView;
    if (this.state.loading) {
      displayOutput = <div className="custom-pgi-loading-spinner">
        <img src={Spinner} alt="Loading..."></img>
      </div>
    }
    else {
      if (this.state.clients) {
        clientsOptions = this.state.clients.map(client => ({ label: client.partyName, value: client.partyId }));
        displayOutput = <div className="client-select">
          <Fragment >
            <Select classNamePrefix="client-select"
              defaultValue={null}
              isClearable={true}
              isSearchable={true}
              name="client"
              options={clientsOptions}
              onChange={this.clientSelectChange.bind(this)}>
            </Select>
          </Fragment></div>
      }
      else {
        displayOutput = <div><p>Something went wrong retrieving the list of clients. Please try again later</p></div>
      }
    }

    if (this.state.loadingView) {
      displayReportView = <div className="custom-pgi-loading-spinner">
        <img src={Spinner} alt="Loading..."></img>
      </div>
    }
    else {
      if (this.state.currentSelection !== null) {
        if (this.state.clientReports) {
          displayReportView = <div className="custom-pgi-report-results-container">
            <h5>Found {this.state.clientReports.reports?.length || "N/A"} Result{this.state.clientReports.reports?.length === 1 ? '' : 's'}</h5>
            <div className="mb-2 text-muted">Reports for the selected client are listed below. To add, edit, or delete a report, please click the Manage Reports button.</div>
            <Link to={reportTo}>
              <button type="button" className="btn btn-info">Manage Reports</button>
            </Link>
            <br />
            <br />
            <div className="custom-pgi-report-results-records">
              {
                this.state.clientReports.reports.map((report, index) =>
                  <Card className="custom-pgi-report-record-card" key={report.reportId}>
                    <CardBody>
                      <CardTitle><b>ID:</b> {report?.reportId || "N/A"}</CardTitle>
                      <CardText>
                        <b>Consolidated Report:</b> {report.isConsolidated ? "Yes" : "No"} <br />
                        <b>Display Portfolio:</b> {!report.hideEntities ? "Yes" : "No"} <br />
                        <b>Workflow Approved Report:</b> {report.workflowApproved ? "Yes" : "No"} <br />
                      </CardText>
                      <Button className="btn-primary" id={"toggler-" + index}>
                        View Report ({report.entities.length} Portfolio{report.entities.length === 1 ? '' : 's'})
                      </Button>
                      <UncontrolledCollapse toggler={'#toggler-' + index}>
                        <Table className="custom-pgi-entity-table" striped>
                          <thead>
                            <tr>
                              <th>Business Code</th>
                              <th>Name</th>
                              <th>Portfolio Active Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              report.entities.map(entity =>
                                <tr key={entity.entityId}>
                                  <th>{entity.entityRefId}</th>
                                  <th>{entity.entityName}</th>
                                  {entity.isTerminated === true ? <th style={{color: 'red'}}>{"Terminated"}</th> : <th>{"Active"}</th>}
                                </tr>
                              )
                            }
                          </tbody>
                        </Table>
                      </UncontrolledCollapse>
                    </CardBody>
                  </Card>)
              }
            </div>
          </div>
        }
        else {
          displayReportView = <div className="custom-pgi-report-results-container">
            <h5>No Results Found</h5>
            <div className="mb-2"><h6 className="text-muted">I'm sorry, we couldn't find any report results for that client. Please select a different client or click the Manage Reports button below to get started adding a new Report for this client.</h6></div>
            <Link to={reportTo}>
              <button type="button" className="btn btn-info">Manage Reports</button>
            </Link>
          </div>
        }
      }
      else {
        displayReportView = <div className="custom-pgi-report-results-container">
          <h5>Select a Client</h5>
          <div className="mb-2"><h6 className="text-muted">Please select a client from above to view its existing report mappings.</h6></div>
        </div>
      }
    }

    return (
      <div>
        <div className="custom-pgi-client-selector-container">
          {displayOutput}
        </div>
        <div className="custom-pgi-client-report-results">
          {displayReportView}
        </div>
      </div>
    );
  }
}

export default Clients;
